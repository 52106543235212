import React from 'react';

import { trackSignUp } from '../utils/tracking';

export const MONTHLY = 'monthly';
export const ANNUALLY = 'annually';

const HOUR = 60;

const calculateYearlyVolume = (monthlyVolume) => monthlyVolume * 12;
const calculateMonthlyPrice = (yearlyPrice) => yearlyPrice / 12;
const formatMoney = (amount, currency) => {
  switch (currency) {
    case 'USD':
      return `$${amount}`;
    default:
      return amount;
  }
};

const PricingPlan = ({
  name,
  monthlyTimeVolume,
  monthlyPrice,
  yearlyPrice,
  billing,
  btnBackground,
  link,
  currency = 'USD',
}) => {
  let volume = monthlyTimeVolume;
  let price = monthlyPrice;
  let unit = 'minutes';

  if (billing === ANNUALLY) {
    volume = calculateYearlyVolume(monthlyTimeVolume);
    price = calculateMonthlyPrice(yearlyPrice);
  }

  if (volume >= HOUR) {
    volume /= HOUR;
    unit = volume === 1 ? 'hour' : 'hours';
  }

  return (
    <div className="flex-grow flex-shrink-0 max-w-xs rounded-lg pr-0.5 border-l-6 border-b-6">
      <div className="relative -top-0.5 -right-0.5 border-l-4 border-b-4 border-t-2 border-r-2 rounded text-center bg-gray">
        <div
          className="py-3 mb-12 text-3xl font-light bg-black text-gray lg:mb-14 lg:text-4xl"
          style={{ clipPath: 'ellipse(50% 100% at 50% 0)' }}
        >
          {name}
        </div>
        <p className="leading-loose">
          Total video capacity
          <br />
          <span className="inline-block mr-1 text-2xl font-bold lg:text-3xl">
            {volume}
          </span>{' '}
          {unit}
          {/* <br />
          per {billing === MONTHLY ? 'month' : 'year'} */}
        </p>
        <p className="mt-4 leading-loose">No limit on video size</p>
        <p className="leading-loose">Unlimited number of videos</p>
        <a
          href={link}
          className={`${btnBackground} my-8 py-3 w-40 inline-block rounded-lg text-lg antialiased text-white font-bold lg:py-3.5`}
          onClick={() => trackSignUp('pricing')}
        >
          {formatMoney(price, currency)}{' '}
          <span className="text-sm">/ month</span>
        </a>
      </div>
    </div>
  );
};

export default PricingPlan;
