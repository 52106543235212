import React from 'react';

import CheckIcon from './CheckIcon';

const FeatureItem = ({ children }) => {
  return (
    <li className="flex items-center">
      <span className="flex-shrink-0 w-6">
        <CheckIcon />
      </span>{' '}
      <span className="ml-3">{children}</span>
    </li>
  );
};

export default FeatureItem;
