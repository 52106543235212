import React from 'react';

const UploadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 72 72"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M24 39l12-12 12 12h-7.65v9h-8.7v-9H24zm12-27c11.245 0 20.684 7.734 23.288 18.173C66.486 31.275 72 37.493 72 45c0 8.284-6.716 15-15 15H18C8.059 60 0 51.941 0 42c0-8.945 6.525-16.367 15.076-17.764C19.19 16.933 27.019 12 36 12c7.497 0 7.497 0 0 0zM16.043 30.158l2.845-.465 1.415-2.511C23.398 21.688 29.273 18 36 18c8.426 0 15.513 5.795 17.466 13.625l.967 3.875 3.947.604A9.004 9.004 0 0166 45a9 9 0 01-9 9H18c-6.627 0-12-5.373-12-12 0-5.958 4.348-10.912 10.043-11.842z"
        />
      </g>
    </svg>
  );
};

export default UploadIcon;
