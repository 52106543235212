import React from 'react';

const CheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M20.839 6.695L8.818 18.715 3.161 13.06l1.41-1.41 4.247 4.236 10.61-10.6 1.41 1.41z"
      />
    </svg>
  );
};

export default CheckIcon;
