import React from 'react';

const SectionSubheading = ({ children }) => {
  return (
    <h3 className="mt-14 mb-8 text-3xl font-bold text-beige sm:text-5xl md:mt-20 md:mb-12 lg:text-6xl lg:leading-tight lg:mt-24 lg:mb-10">
      {children}
    </h3>
  );
};

export default SectionSubheading;
