import * as React from 'react';
import { Link, graphql } from 'gatsby';
import classNames from 'classnames';

import Container from '../components/Container';
import Layout from '../components/Layout';
import SectionHeading from '../components/SectionHeading';
import SectionSubheading from '../components/SectionSubheading';
import PricingPlan, { MONTHLY, ANNUALLY } from '../components/PricingPlan';
import FeatureItem from '../components/FeatureItem';
import GetStartedItem from '../components/GetStartedItem';
import UserIcon from '../components/UserIcon';
import UploadIcon from '../components/UploadIcon';
import PlayerIcon from '../components/PlayerIcon';
import SEO from '../components/SEO';

import { trackSignUp } from '../utils/tracking';

import RelaxingImg from '../images/relaxing.svg';
import KiteImg from '../images/kite.svg';
import SurfingImg from '../images/surfing.svg';
import ScrollIcon from '../images/scroll.svg';

const activeBillingClassName = 'font-bold text-beige bg-black';

const IndexPage = ({ data }) => {
  const [billing, setBilling] = React.useState(MONTHLY);

  return (
    <Layout>
      <SEO
        title="Wolvido"
        description="Post a video on your website quickly and without hassle. With us, it's a matter of seconds."
      />
      <section>
        <Container className="py-10 md:py-16 lg:py-20">
          <div className="flex items-center justify-around">
            <SectionHeading>
              Simple
              <br />
              Video
              <br />
              Hosting
            </SectionHeading>
            <div className="w-1/3 md:w-1/2">
              <img src={RelaxingImg} alt="" />
            </div>
          </div>
          <div className="mt-10 text-center md:mt-16">
            <Link to="/#pricing" className="btn-yellow">
              See Plans
            </Link>
          </div>
        </Container>
      </section>
      <section id="why">
        <div className="bg-black">
          <Container className="pb-14 md:pb-16 lg:pb-20">
            <div className="flex flex-col items-center">
              <SectionSubheading>The WHY.</SectionSubheading>
              <div className="w-full">
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src={data.site.siteMetadata.promoVideoUrl}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                    title="wolvido-explainer"
                  ></iframe>
                </div>
              </div>
              <div className="mt-10 text-center">
                <a
                  href={data.site.siteMetadata.signupUrl}
                  className="btn-yellow"
                  onClick={() => trackSignUp('why')}
                >
                  Sign Up Now
                </a>
              </div>
            </div>
          </Container>
        </div>
      </section>
      <section id="pricing">
        <Container className="py-10 md:py-16 lg:py-20">
          <div className="flex items-center justify-center md:justify-between">
            <SectionHeading>
              Simple
              <br />
              Transparent
              <br />
              Pricing
            </SectionHeading>
            <div className="w-1/2 lg:w-2/3">
              <img src={KiteImg} alt="" />
            </div>
          </div>
          {/* <div className="flex h-12 mx-auto my-10 overflow-hidden rounded-full w-52 bg-gray md:w-72 md:h-14 md:my-14 md:text-lg">
            <button
              type="button"
              className={classNames('flex-grow focus:outline-none', {
                [activeBillingClassName]: billing === MONTHLY,
              })}
              onClick={() => setBilling(MONTHLY)}
            >
              Monthly
            </button>
            <button
              type="button"
              className={classNames('flex-grow focus:outline-none', {
                [activeBillingClassName]: billing === ANNUALLY,
              })}
              onClick={() => setBilling(ANNUALLY)}
            >
              Annually
            </button>
          </div> */}
          <div className="flex justify-center pt-1 pb-4 mt-10 space-x-6 overflow-auto md:mt-14 lg:space-x-7">
            <PricingPlan
              name="Basic"
              monthlyTimeVolume="60"
              monthlyPrice="9"
              yearlyPrice="108"
              billing={billing}
              btnBackground="bg-blue"
              link={data.site.siteMetadata.signupUrl}
            />
            {/* <PricingPlan
              name="M"
              monthlyTimeVolume="120"
              monthlyPrice="10"
              yearlyPrice="24"
              billing={billing}
              btnBackground="bg-blue-dark"
            />
            <PricingPlan
              name="L"
              monthlyTimeVolume="300"
              monthlyPrice="20"
              yearlyPrice="36"
              billing={billing}
              btnBackground="bg-blue-darker"
            />
            <PricingPlan
              name="XL"
              monthlyTimeVolume="900"
              monthlyPrice="50"
              yearlyPrice="240"
              billing={billing}
              btnBackground="bg-blue-darkest"
            /> */}
          </div>
          {/* <div className="w-10 mx-auto opacity-80 lg:hidden">
            <img src={ScrollIcon} alt="Scroll horizontally" />
          </div> */}
          <p className="mt-5 text-lg text-center md:text-xl md:mt-10">
            Any questions? You may want to check our{' '}
            <Link to="/help/faq/" className="border-b-2">
              FAQ
            </Link>
            .
          </p>
        </Container>
        <div className="bg-black">
          <Container>
            <div className="flex flex-col items-center lg:flex-row lg:items-start">
              <SectionSubheading>Each plan includes:</SectionSubheading>
              <ul className="flex-shrink-0 space-y-4 text-lg antialiased font-bold mb-14 text-yellow md:mb-16 md:text-xl lg:mt-24 lg:mb-20">
                <FeatureItem>Ad-free experience</FeatureItem>
                <FeatureItem>HD video resolution</FeatureItem>
                <FeatureItem>
                  Smooth playback even with low speed connection
                </FeatureItem>
                <FeatureItem>Lightning fast delivery to viewers</FeatureItem>
                <FeatureItem>
                  Beautiful responsive minimalistic player
                </FeatureItem>
                <FeatureItem>Flexible player customization</FeatureItem>
                <FeatureItem>Seamless integration</FeatureItem>
              </ul>
            </div>
          </Container>
        </div>
      </section>
      <section id="get-started">
        <Container className="py-10 md:py-16 lg:py-20">
          <div className="flex items-center justify-around md:justify-between">
            <SectionHeading>
              Get
              <br />
              Started
              <br />
              Now
            </SectionHeading>
            <div className="w-1/2 md:w-3/5">
              <img src={SurfingImg} alt="" />
            </div>
          </div>
          <div className="flex justify-center my-10 md:my-14 lg:block lg:w-max lg:mx-auto lg:my-16 lg:border-l-6 lg:border-b-6 lg:rounded-lg">
            <ol className=" space-y-5 md:space-y-7 lg:flex lg:space-y-0 lg:relative lg:-top-0.5 lg:-right-0.5 lg:border-l-4 lg:border-b-4 lg:border-t-2 lg:border-r-2 lg:rounded lg:bg-gray">
              <GetStartedItem num="1" icon={<UserIcon />}>
                <a
                  href={data.site.siteMetadata.signupUrl}
                  className="origin-left btn-yellow"
                  onClick={() => trackSignUp('get-started')}
                >
                  Sign Up
                </a>
              </GetStartedItem>
              <GetStartedItem
                num="2"
                icon={<UploadIcon />}
                style={{ animationDelay: '5s' }}
              >
                <span className="lg:pt-3.5">Upload a video</span>
              </GetStartedItem>
              <GetStartedItem
                num="3"
                icon={<PlayerIcon />}
                style={{ animationDelay: '10s' }}
              >
                <span className="lg:pt-3.5">
                  Integrate player into your page
                </span>
              </GetStartedItem>
            </ol>
          </div>
          <p className="mt-5 text-lg text-center md:text-xl md:mt-10">
            In case you need a hand,{' '}
            <Link to="/help/" className="inline-block leading-none border-b-2">
              visit our help center
            </Link>
            .
          </p>
        </Container>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        signupUrl
        promoVideoUrl
      }
    }
  }
`;

export default IndexPage;
