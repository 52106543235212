import React from 'react';

const PlayerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 72 72"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M60 63c3.309 0 6-2.691 6-6V15c0-3.309-2.691-6-6-6H12c-3.309 0-6 2.691-6 6v42c0 3.309 2.691 6 6 6h48zM12 21h48l.003 36H12V21z"
        />
        <path
          transform="translate(17 21)"
          fill="currentColor"
          fillRule="nonzero"
          d="M12.472 29.62L29.62 18.709 12.47 7.795V29.62z"
        />
      </g>
    </svg>
  );
};

export default PlayerIcon;
