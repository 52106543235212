import React from 'react';

const SectionHeading = ({ children }) => {
  return (
    <h2 className="flex-shrink-0 text-4xl leading-tight font-display font-bold uppercase sm:text-6xl sm:leading-tight lg:text-7xl lg:leading-tight">
      {children}
    </h2>
  );
};

export default SectionHeading;
