import React from 'react';

const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 72 72"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M36 42c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12c4.418 0 4.418 0 0 0zm0-6a6 6 0 100-12 6 6 0 000 12c2.21 0 2.21 0 0 0zm17.778 24.167C61.191 54.705 66 45.914 66 36 66 19.431 52.569 6 36 6 19.431 6 6 19.431 6 36c0 9.914 4.809 18.705 12.222 24.167A30.097 30.097 0 0022.908 63 29.88 29.88 0 0036 66a29.88 29.88 0 0017.778-5.833c4.942-3.641 4.942-3.641 0 0zm-2.092-6.002C56.778 49.764 60 43.258 60 36c0-13.255-10.745-24-24-24S12 22.745 12 36c0 7.258 3.222 13.764 8.314 18.165C23.402 48.694 29.27 45 36 45s12.598 3.694 15.686 9.165c3.395-2.934 3.395-2.934 0 0zm-5.016 3.339A12 12 0 0036 51a12 12 0 00-10.67 6.504A23.901 23.901 0 0036 60c3.833 0 7.456-.899 10.67-2.496-1.33-2.576-1.33-2.576 0 0z"
        />
      </g>
    </svg>
  );
};

export default UserIcon;
