import React from 'react';

const GetStartedItem = ({ num, icon, children, ...passThroughProps }) => {
  return (
    <li
      className="flex items-center text-lg md:text-xl lg:w-72 lg:h-80 lg:px-4 lg:flex-col lg:text-center lg:animate-highlight"
      {...passThroughProps}
    >
      <div className="hidden lg:block lg:w-16 lg:mt-14 lg:mb-10">{icon}</div>
      <span className=" mr-3 text-2xl sm:mr-5 sm:text-3xl sm:font-light md:text-4xl lg:mr-0 lg:mb-4">
        {num})
      </span>
      {children}
    </li>
  );
};

export default GetStartedItem;
